<template>
  <div class="collect_detail">
    <div class="collect_image">
      <el-image :src="collectDetails.main && collectDetails.main.url ? collectDetails.main.url : ''">
        <div slot="placeholder" class="image-slot">
          加载中<i class="el-icon-loading"></i>
        </div>
      </el-image>
    </div>
    <div class="status">
      <div class="like" @click="love = !love">收藏</div>
      <div class="like" @click="love = !love">
        <i class="iconfont iconaixin-kuai" :style="{color: love ? '#ed1941' : '#eee'}"></i> 
        喜欢 {{ collectDetails.nums && collectDetails.nums[0] && collectDetails.nums[0].like_nums ? collectDetails.nums[0].like_nums : 0 }}
      </div>
    </div>
    <div class="desc">
      {{ collectDetails.main && collectDetails.main.info ? collectDetails.main.info : '' }}
    </div>
    <div class="author" @click="userCenter">
      <div class="avatar">
        <el-avatar shape="square" size="large" src="https://acg.xydwz.cn/gqapi/gqapi.php"></el-avatar>
      </div>
      <div class="name_and_create">
        <div class="name">未知用户</div>
        <div class="create">于 未知时间 采集</div>
      </div>
    </div>
    <div class="board" @click="userBoard">
      <div class="more_collect_h">
        未知画板
        <i class="el-icon-arrow-right arrow_r"></i>
      </div>
      <div class="more_collect_f">
        <img src="https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=205719288,3873431968&fm=26&gp=0.jpg" v-for="item in 10" :key="item">
      </div>
    </div>
    <div class="comments">
      <div class="comment_h" @click="unfold = !unfold">
        {{ collectDetails.nums && collectDetails.nums[0] && collectDetails.nums[0].comm_nums ? collectDetails.nums[0].comm_nums : 0 }}条评论
        <i class="el-icon-d-arrow-right unfoldFlag" :class="[{'unfold': unfold}]"></i>
      </div>
      <div class="comment_b" :class="[{'show': unfold}]">
        <div class="add_comment">
          <div class="avatar">
            <el-avatar shape="square" size="large" src="https://acg.xydwz.cn/gqapi/gqapi.php"></el-avatar>
          </div>
          <div class="comment_input">
            <textarea placeholder="添加评论" v-model="comment"></textarea>
            <div class="publish" @click="publishComment">发表评论</div>
          </div>
        </div>
        <div class="commentList">
          <div class="one_line" v-for="(item, index) in collectDetails.comm_list" :key="index">
            <div class="one_line_l">
              <el-avatar shape="square" size="large" :src="item.pic ? item.pic : 'https://acg.xydwz.cn/gqapi/gqapi.php'"></el-avatar>
            </div>
            <div class="one_line_r">
              <div class="name">
                {{ item.username ? item.username : '用户????'}}
                <span class="create">发表于 {{ item.created }}</span>
              </div>
              <div class="text">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="likes">
      <div class="label">喜欢</div>
      <el-avatar shape="square" size="large" :src="item.pic ? item.pic : 'https://acg.xydwz.cn/gqapi/gqapi.php'" v-for="(item, index) in collectDetails.like_list" :key="index"></el-avatar>
    </div>
    <div class="recommend">
      <div class="title">为你推荐的采集</div>
      <mp-waterfall :data="mydata" @loadmore="loadmore"></mp-waterfall>
    </div>
  </div>
</template>

<script>
import MpWaterfall from '@/mp/components/mp-waterfall';
export default {
  components: {
    MpWaterfall
  },
  data() {
    return {
      collectId: '',
      collectDetails: {},
      mydata: [],
      detail: {
        desc: 'task app action task list manager task dribbble mimimal ios dashboard us design app ui'
      },
      commentList: [
        {name: '小明', comment: '哈哈哈哈哈哈哈'},
        {name: '小红', comment: '嘻嘻嘻嘻嘻嘻喜'},
        {name: '小军', comment: '呃呃呃鹅鹅鹅鹅'},
        {name: '渣渣猫', comment: '阿法兰克福华南虎开发了发给你方法会临时工那款给很难看发挥好了'},
      ],
      comment: '',
      unfold: false, // 展开评论
      love: false,
    }
  },
  methods: {
    publishComment() {

    },
    loadmore() {
      if (this.mydata.length > 30) {
        return false;
      }
      setTimeout(() => {
        this.mydata = this.mydata.concat(this.mydata, this.mydata)
      }, 2000)
    },
    userCenter() {
      this.$router.push({
        path: '/mCollectUser',
        query: {id: Math.random(1, 100)}
      });
    },
    userBoard() {
      this.$router.push({
        path: '/mCollectBoard',
        query: {id: Math.random(1, 100)}
      });
    },
    getCollectDetails() {  // 采集详情
      this.$service.DEFAULT({
        url: '/auth/ppt/fodder/details',
        method: 'GET',
        params: {
          item_id: this.collectId,
        },
      }).then(res => {
        if (res.code == 200) {
          this.collectDetails = res.data ? res.data : {};
        }
      })
    },
  },
  created() {
    this.collectId = this.$route.query.pins;
    this.getCollectDetails();
  },
}
</script>

<style lang="scss" scoped>
.collect_detail {
  background: #fff;
  padding-bottom: 70px;
  .collect_image {
    text-align: center;
  }
  .status {
    padding: 10px;
    .like {
      display: inline-block;
      border: 1px solid #ededed;
      border-radius: 2px;
      padding: .25em .3em;
      margin-right: 0.5em;
      &:active {
        transition: transform .12s ease-in-out;
        transform: scale(0.9, 0.9);
      }
    }
  }
  .desc {
    background: #fafafa;
    padding: 10px;
    line-height: 1.5;
    color: #444;
    font-size: 1rem;
  }
  .author {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ededed;
    .avatar {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
    .name_and_create {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .name {
        color: #444;
      }
      .create {
        color: #999;
        font-size: 0.8rem;
      }
    }
  }
  .board {
    padding: 10px;
    border-bottom: 1px solid #ededed;
    .more_collect_h {
      position: relative;
      color: #222;
      margin-bottom: 6px;
      .arrow_r {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 600;
      }
    }
    .more_collect_f {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      img {
        width: 15%;
        margin-right: 2%;
      }
    }
  }
  .comments {
    .comment_h {
      padding: 10px;
      color: #222;
      position: relative;
      border-bottom: 1px solid #ededed;
      .unfoldFlag {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%) rotateZ(90deg);
        transition: transform 360ms;
      }
      .unfold {
        transform: translateY(-50%) rotateZ(-90deg);
      }
    }
    .comment_b {
      height: 0;
      overflow: hidden;
      &.show {
        height: auto;
      }
      .add_comment {
        display: flex;
        padding: 10px;
        .avatar {
          margin-right: 10px;
        }
        .comment_input {
          flex: 1;
          textarea {
            width: 100%;
            height: 60px;
            box-sizing: border-box;
            padding: 2px 6px;
            border: 1px solid #dadada;
            border-radius: 2px;
            resize: none;
            font-size: 18px;
            outline: 0;
          }
          .publish {
            float: right;
            border: 1px solid #ededed;
            border-radius: 2px;
            padding: 3px 6px;
            &:active {
              background-color: #f4f4f4;
              transition: transform .12s ease-in-out;
              transform: scale(0.9, 0.9);
            }
          }
        }
      }
      .commentList {
        border-top: 1px solid #ededed;
        .one_line {
          display: flex;
          padding: 10px;
          border-bottom: 1px solid #ededed;
          .one_line_l {
            margin-right: 10px;
          }
          .one_line_r {
            .name {
              color: #222;
              font-size: 1.2rem;
              .create {
                font-size: 0.8rem;
                color: #999;
              }
            }
            .text {
              color: #666;
            }
          }
        }
      }
    }
  }
  .likes {
    padding: 10px;
    border-bottom: 1px solid #ededed;
    .label {
      margin-bottom: 8px;
    }
    .el-avatar {
      margin: 0 8px 8px 0;
    }
  }
  .recommend {
    .title {
      font-weight: 700;
      color: #444;
      margin: 10px 0 0 10px;
    }
  }
}
</style>