<template>
<div class="mp_waterfall">
  <waterfall class="waterfall_wapper" :col="col" :data="data" @loadmore="loadmore" ref="waterfall" v-if="data.length > 0">
    <div class="item" v-for="(item, index) in data" :key="index">
      <!-- 待公开 -->
      <div class="private" v-if="!item.url">
        <div class="content" :style="getStyleObj(index)">
          <i class="iconfont iconsuo"></i>
          <div class="lock"><span>该采集待公开</span></div>
        </div>
      </div>
      <!-- 已公开 -->
      <div class="public" @click="viewDetails(item.id)" v-if="item.url">
        <div class="public_image">
          <img class="image" :lazy-src="item.url" alt="图片加载失败" />
        </div>
        <div class="public_desc">
          <p>
            {{item.info}}
          </p>
        </div>
        <div class="public_status">
          <div class="like">
            <i class="iconfont iconaixin-kuai"></i>
            <span> {{item.coll_num}}</span>
          </div>
          <div class="comment">
            <i class="el-icon-s-comment"></i>
            <span> {{item.like_num}}</span>
          </div>
        </div>
        <div class="public_author">
          <div class="avatar">
            <el-avatar size="large" :src="item.picture" v-if="item.picture"></el-avatar>
            <img class="default_avatar" src="../../assets/img/title_icon.png" v-else>
          </div>
          <div class="info">
            <div class="user_name" @click.stop="userCenter">{{item.username}}</div>
            <div class="board_name" @click.stop="userBoard">{{item.cat_title}}</div>
          </div>
        </div>
      </div>
    </div>
  </waterfall>
  <div style="margin-top:100px;" v-else>
    <div style="width:100%;text-align:center;color:#666;font-size:1rem">无相关采集</div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: function () {
        return []
      },
    }
  },
  data() {
    return {
      deviceWidth: '',
      lockBgColor: ['#8dfff7', '#ecc9c7', '#d1cfc0', '#abf9d0', '#c49ac7', '#93dd9b', '#c49ac7', '#b6e284', '#9adbf9', '#70d2db'],
    }
  },
  computed: {
    col() {
      return this.deviceWidth <= 320 ? 1 : this.deviceWidth <= 414 ? 2 : this.deviceWidth <= 768 ? 3 : 4;
    },
  },
  methods: {
    loadmore() {  // 滚动到底部加载更多数据
      this.$emit('loadmore');
    },
    getStyleObj(index) {  // 非公开样式
      let i = index % this.lockBgColor.length > this.lockBgColor.length ? 1 : index % this.lockBgColor.length;
      return {
        'background-color': this.lockBgColor[i] + ''
      }
    },
    viewDetails(id) {
      this.$router.push({
        path: '/mCollectDetails',
        query: { pins: id }
      });
    },
    userCenter() {
      this.$router.push({
        path: '/mCollectUser',
        query: {id: Math.random(0, 100)},
      });
    },
    userBoard() {
      this.$router.push({
        path: '/mCollectBoard',
        query: {id: Math.random(0, 100)},
      });
    },
  },
  mounted() {
    this.deviceWidth = document.documentElement.offsetWidth;
  }
}
</script>

<style lang="scss" scoped>
.mp_waterfall {
  box-sizing: border-box;
  width: 100%;
}
.waterfall_wapper {
  box-sizing: border-box;
  width: 100%;
  padding-top: 10px;
  .item {
    box-sizing: border-box;
    padding: 0 5px;
    margin-bottom: 10px;
    .private,
    .public {
      box-sizing: border-box;
      width: 100%;
      overflow: hidden;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 1px 3px rgba(0,0,0,.02), 0 4px 8px rgba(0,0,0,.02);
      transition: transform .15s ease-in-out,box-shadow .15s ease-in-out;
      position: relative;
    }
    .private {
      padding-bottom: 100%;
      cursor: pointer;
      .content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #8dfff7;
        &:hover {
          .lock {
            display: block;
          }
        }
        .iconsuo {
          font-size: 3em;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .lock {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background: rgba(229,229,229,.75);
          > span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            font-size: 14px;
            color: #444;
            font-size: 1.5em;
            white-space: nowrap;
          }
        }
      }
    }
    .public {
      &:hover {
        .public_content__action {
          top: 10px;
          transform: translateY(0);
        }
      }
      .public_image {
        min-height: 100px;
        max-height: 350px;
        overflow: hidden;
        display: flex;
        align-items: center;
        cursor: pointer;
        .image {
          display: block;
          width: 100%;
          height: auto;
          text-align: center;
        }
      }
      .public_desc {
        > p {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          padding: 0 16px;
          margin: 10px 0;
          word-wrap: break-word;
          font-size: 1rem;
          color: #444;
        }
      }
      .public_status {
        padding: 0 15px;
        margin: 10px 0;
        color: #8c7e7e;
        .like {
          display: inline-block;
          margin-right: 10px;
          .iconaixin-kuai {
            font-size: 14px;
            color: #ed1941;
          }
        }
        .comment {
          display: inline-block;
          margin-right: 10px;
        }
      }
      .public_author {
        padding: 4px 15px;
        color: #999;
        border-top: 1px solid #f2f2f2;
        display: flex;
        align-items: center;
        .avatar {
          margin-right: 10px;
          display: flex;
          align-items: center;
          .default_avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }
        .info {
          font-size: 14px;
          color: #9e7e6b;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .user_name {
            margin-bottom: .3em;
          }
        }
      }
    }
  }
}
</style>